import {
  useClaimSuspense,
  useSimplifiedClaimSuspense,
} from '@airhelp/webapp/react';
import { useParams } from 'react-router';
import { VStack, Flex, Text, useDisclosure, Link } from '@chakra-ui/react';
import { InfoBox } from '@airhelp/react';
import { Info, WhatsApp } from '@airhelp/icons';
import { formatDate, differenceInMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as DetailsBox from 'components/DetailsBox';
import { TravelDetailsDrawer } from 'components/TravelDetails';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import config from 'config';
import { useTrackEvent } from 'utils/tracking/hooks';

export function Details() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { userProfile } = useMyProfile();
  const { trackPageInteractions } = useTrackEvent();
  const {
    isOpen: isTravelDetailsDrawerOpen,
    onOpen: openTravelDetailsDrawer,
    onClose: closeTravelDetailsDrawer,
  } = useDisclosure();

  if (!id) {
    throw new Error('Id param is required for details component to work');
  }

  const { data: claim } = useClaimSuspense({
    params: { id: Number(id) },
  });

  const { data: claimSimplified } = useSimplifiedClaimSuspense({
    params: {
      id: Number(id),
      locale: i18n.language,
    },
  });

  const disruptedFlight = claimSimplified.itinerary.flights.find(
    (flight) => flight.disrupted,
  );

  const passengersData = claim.fellowPassengers;

  const mainPaxData = {
    name: claim.claimant.fullName,
    email: claim.claimant.email,
    phoneNumber: claim.claimant?.phoneNumber ?? null,
    address: claim.address.formattedAddress,
  };

  // claim extra expenses should be available within an hour after claim creation, adding extra 30 minutes for safety
  const claimExpensesAvailable =
    differenceInMinutes(Date.now(), claim.createdAt) > 90;

  const claimExtraExpenseTranslation = claimSimplified.extraExpense
    ? t('claims.details.yes')
    : t('claims.details.no');

  const formatDelay = (delay: number | null) => {
    if (!delay) {
      return 'n/A';
    }

    if (delay === -1) {
      return t('claims.flight_delays.never_arrived');
    }

    if (delay === 1) {
      return t('claims.flight_delays.bellow_two_hours');
    }

    const delayToHours = Math.floor(delay / 60);

    return t('claims.flight_delays.above_hours', {
      count: delayToHours,
    });
  };

  return (
    <VStack
      align="stretch"
      spacing={{
        base: 4,
        md: 6,
      }}
    >
      <DetailsBox.Root title={t('claims.details.itinerary')}>
        <DetailsBox.DataGroup>
          <DetailsBox.Row
            label={t('claims.details.airline')}
            value={claimSimplified.disruptedFlightAirlineName ?? '-'}
          />

          <DetailsBox.Row
            label={t('claims.details.date')}
            value={formatDate(
              disruptedFlight?.flightDate as string,
              'd MMMM, yyyy',
            )}
          />

          <DetailsBox.Row
            label={t('claims.details.booking_reference')}
            value={claimSimplified.bookingRef}
          />
          <DetailsBox.Row
            label={t('claims.details.travel_details')}
            value={t('claims.details.more')}
            dataTestId="travel-details"
            onValueClick={() => {
              trackPageInteractions(
                'drawer open',
                'more',
                'claim details page - details',
              );
              openTravelDetailsDrawer();
            }}
          />
        </DetailsBox.DataGroup>
      </DetailsBox.Root>

      <DetailsBox.Root title={t('claims.details.disruption')}>
        <DetailsBox.DataGroup>
          <DetailsBox.Row
            label={t('claims.details.disruption_type')}
            value={claimSimplified.claimType}
          />
          <DetailsBox.Row
            label={t('claims.details.delay_at_destination')}
            value={formatDelay(claimSimplified.delay)}
          />
          <DetailsBox.Row
            label={t('claims.details.extra_expenses')}
            value={
              claimExpensesAvailable ? claimExtraExpenseTranslation : 'Pending'
            }
          />
        </DetailsBox.DataGroup>
      </DetailsBox.Root>
      <DetailsBox.Root title={t('claims.details.passengers')}>
        <DetailsBox.DataGroup
          title={mainPaxData.name}
          subtitle={t('claims.details.main_passenger')}
        >
          <DetailsBox.Row
            label={t('claims.details.email')}
            value={mainPaxData.email}
          />
          <DetailsBox.Row
            label={t('claims.details.phone_number')}
            value={userProfile?.phoneNumber ?? '-'}
          />
          <DetailsBox.Row
            label={t('claims.details.address')}
            value={mainPaxData.address}
          />

          <Flex w="100%" justifyContent="space-between" gap={8}>
            <Flex gap={2}>
              <WhatsApp color="#25D366" width="20px" height="20px" />
              <Text color="#25D366" fontSize="sm" fontWeight={700}>
                WhatsApp
              </Text>
            </Flex>
            <Text
              fontSize="sm"
              color="primary.900"
              fontWeight="medium"
              sx={{
                textWrap: 'wrap',
                wordBreak: 'break-word',
              }}
            >
              {userProfile?.consents?.whatsappConsent
                ? t('claims.details.yes')
                : t('claims.details.no')}
            </Text>
          </Flex>
        </DetailsBox.DataGroup>
        {passengersData.map((passenger) => (
          <DetailsBox.DataGroup
            key={passenger.id}
            title={passenger.fullName}
            subtitle={t('claims.details.additional_passenger')}
          >
            <DetailsBox.Row
              label={t('claims.details.minor')}
              value={
                passenger.minor
                  ? t('claims.details.yes')
                  : t('claims.details.no')
              }
            />

            {passenger?.email ? (
              <DetailsBox.Row
                label={t('claims.details.email')}
                value={passenger?.email}
              />
            ) : null}
          </DetailsBox.DataGroup>
        ))}
      </DetailsBox.Root>

      <InfoBox
        icon={<Info />}
        variant="primary"
        border="1px solid"
        borderColor="greyscale.400"
      >
        <Flex flexWrap="wrap" gap={1}>
          <Text color="greyscale.700" fontSize="md">
            {t('claims.details.need_to_update')}
          </Text>
          <Link isExternal href={config.contactUrl}>
            <Text color="primary.500" fontSize="md">
              {t('claims.details.contact_customer_service')}
            </Text>
          </Link>
        </Flex>
      </InfoBox>
      <TravelDetailsDrawer
        isOpen={isTravelDetailsDrawerOpen}
        onClose={closeTravelDetailsDrawer}
        flights={claim.itinerary.flights}
      />
    </VStack>
  );
}
