import { Check } from '@airhelp/icons';
import { TripTier } from '@airhelp/plus';
import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Journey } from 'models/journey/Journey';

interface IComponent {
  journey: Journey;
}

const B2BBadge: React.FC<IComponent> = ({ journey }) => {
  const { t } = useTranslation();

  if (journey?.trip?.tier) {
    return (
      <HStack data-testid="benefits-b2b-trip">
        <Check color="success.500" />
        <Text
          fontSize="xs"
          color="greyscale.700"
          fontWeight="medium"
          ms="4px !important"
        >
          {journey.trip.tier === TripTier.ESSENTIAL
            ? `${t('dashboard.flying_with_ahp')} ${t('pricing.plans.essential')}`
            : `${t('dashboard.flying_with_ahp')} ${t('pricing.plans.complete')}`}
        </Text>
      </HStack>
    );
  }
  if (journey.journeyProtected) {
    return (
      <VStack>
        <HStack data-testid="benefits-b2b-protected">
          <Check color="success.500" />
          <Text
            fontSize="xs"
            color="greyscale.700"
            fontWeight="medium"
            ms="4px !important"
          >
            {t('common.insurance')}
          </Text>
        </HStack>
      </VStack>
    );
  }
};

export default B2BBadge;
