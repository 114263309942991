import React from 'react';
import { AirlineStops } from '@airhelp/icons';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash';
import { fontFamilyFraktion } from '@airhelp/design-tokens';
import { type Journey } from 'models/journey/Journey';
import { journeyUtils } from 'utils';

interface IComponent {
  journey: Journey;
}

const JourneyFlightCard: React.FC<IComponent> = ({ journey }) => {
  const { t, i18n } = useTranslation();

  const flightsCount = journey.itineraries.reduce(
    (noOfFlights, itinerary) => noOfFlights + itinerary.flights.length,
    0,
  );

  const getJourneyDates = () => {
    const firstFlight = journey.itineraries[0].flights[0];
    const lastItinerary = last(journey.itineraries);
    const lastFlight = last(lastItinerary?.flights);

    if (!firstFlight) return null;

    const firstDate = journeyUtils.useFlightDateFormatted(
      firstFlight.localDepartureDate,
      true,
    );

    if (
      !lastFlight ||
      (journey.itineraries.length === 1 &&
        journey.itineraries[0].flights.length === 1)
    ) {
      return firstDate;
    }

    const lastDate = journeyUtils.useFlightDateFormatted(
      lastFlight.localDepartureDate,
      true,
    );
    return `${firstDate} - ${lastDate}`;
  };

  const journeyName = journey.generateDisplayName(t, i18n.language);

  return (
    <HStack width="100%">
      <AirlineStops color="primary.500" boxSize={6} mr={2} />
      <Box width="100%" height="40px">
        <Heading
          title={journeyName}
          variant="h5"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          fontSize="md"
          fontWeight={500}
          maxWidth={{
            base: '206px',
            sm: '100%',
            md: '178px',
            lg: '212px',
            xlg: '100%',
            xl: '242px',
            '2xl': '272px',
          }}
          data-testid="journey-name"
        >
          {journeyName}
        </Heading>
        <Text
          color="greyscale.600"
          fontSize="xs"
          fontWeight={700}
          fontFamily={fontFamilyFraktion}
          textTransform="uppercase"
          data-testid="journey-dates-and-flights"
        >
          {getJourneyDates()} ∙{' '}
          {t('common.flight_counter', { count: flightsCount })}
        </Text>
      </Box>
    </HStack>
  );
};

export default JourneyFlightCard;
