import React from 'react';
import { generatePath, useNavigate } from 'react-router';
import { HStack, VStack, type StackProps } from '@chakra-ui/react';
import JourneyActions from 'components/Journey/JourneyActions';
import { PAGE_PATHS } from 'config/routes';
import { type Journey } from 'models/journey/Journey';
import { ProtectionBadge } from 'elements';
import JourneyFlightCard from 'components/Journey/JourneyFlightCard';
import { TILE_STYLE } from '../config';
import B2BBadge from '../B2BBadge';

interface IComponent extends StackProps {
  journey: Journey;
}

const JourneyTile: React.FC<IComponent> = ({ journey, ...props }) => {
  const navigate = useNavigate();

  const isTrip = journey.trip !== null;

  const handleCardClick = ({ event }) => {
    event.stopPropagation();

    if (event.target.nodeName !== 'BUTTON') {
      navigate(
        generatePath(PAGE_PATHS.JOURNEY_PAGE, { id: journey.id.toString() }),
      );
    }
  };

  return (
    <VStack
      width="100%"
      onClick={(event) => {
        handleCardClick({ event });
      }}
      {...TILE_STYLE}
      {...props}
      _hover={{
        cursor: 'pointer',
        borderColor: 'primary.500',
        boxShadow: '0px 0px 0px 4px #DFEDFF',
      }}
      justifyContent="space-between"
      data-testid={`tile-active-${journey.id}`}
    >
      <HStack
        width="100%"
        backgroundColor="greyscale.200"
        px={4}
        py={5}
        borderRadius="xl"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <JourneyFlightCard journey={journey} />
        <JourneyActions journey={journey} />
      </HStack>
      <HStack width="100%" px={2} height="20px">
        {isTrip ? (
          <B2BBadge journey={journey} />
        ) : (
          <ProtectionBadge journey={journey} />
        )}
      </HStack>
    </VStack>
  );
};

export default JourneyTile;
